<template>
  <div class="TermsAndConditions">

    <!-- THIS COMMENTED DIV IS A CHECKBOX FOR TERMS AND CONDITIONS -->
    <!-- <div class="o-container-gray u-text-left terms-block">
      <md-field :class="getValidationClassFormAccount('terms')">
        <md-checkbox name="account-terms" id="account-terms" class="md-primary" v-model="formAccount.terms"
          :disabled="sendingFormAccount">{{ $t('termsAndConditions.agreeWith') }}</md-checkbox>
        <span class="o-default-body">
          <router-link :to="links.terms.page">{{ links.terms.text }}</router-link> *
        </span>
        <span class="md-error"
          v-if="!$v.formAccount.terms.requiredIf">{{ $t('termsAndConditions.acceptTermsRequired') }}</span>
      </md-field>
    </div> -->

    <section class="terms">
      <div class="o-default-body-xs">
        {{ $t('termsAndConditions.usageConsent') }}
        <a :href="links.terms.page" @click.prevent="newTabOpenRoute(links.terms.page)">{{ $t('termsAndConditions.termsAndConditions') }}</a>
        {{ $t('termsAndConditions.usageConsent2') }}
        <a :href="links.privacy.page" @click.prevent="newTabOpenRoute(links.privacy.page)">{{ $t('termsAndConditions.privacyPolicy') }}</a>
        {{ $t('termsAndConditions.usageConsent3') }}
      </div>
    </section>
  </div> <!-- /.TermsAndConditions -->
</template>

<script>
export default {
  name: 'TermsAndConditions',
  data() {
    return {
      sendingFormAccount: false,
      formAccount: {
        terms: '',
      },
      links: {
        privacy: {
          page: '/privacy-policy'
        },
        terms: {
          page: '/terms',
          text: this.$t('termsAndConditions.termsAndConditions')
        },
      },
    };
  },
  validations: {
    formAccount: {
      terms: {
        requiredIf: (value) => (value == true)
      },
    },
  },
  methods: {
    newTabOpenRoute(path) {
      let route = this.$router.resolve(path);
      window.open(route.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.TermsAndConditions {

  .terms {
    margin-bottom:.8rem;
    margin-top: 3.2rem;
    text-align: justify;
    text-justify: inter-word;

    .o-default-body-xs {
      line-height: 1.5;
    }
  }
}
</style>
