<template>
  <div class="SignUpView">
    <div v-if="signUpRequestSent">
      <div class="o-container-gray">
        <img src="@/assets/images/checkmark.png"
             alt="Checkmark image"
             class="o-image-medium" />
        <h3 class="o-default-h3">{{ $t('signUpView.activationEmailSent') }} <span class="u-strong">{{ userEmail }}</span>.</h3>
        <h3 class="o-default-h4">{{ $t('signUpView.accessActivationLink') }}</h3>
        <h3 class="o-default-h4 navigate-to-main-page">{{ $t('signUpView.navigateToMainPage') }} </h3>
        <md-button class="md-dense md-raised md-primary navigate-btn"
                    @click="Helpers.goToRoute('', $router)">
          {{ $t('signUpView.mainPage') }}
        </md-button>
      </div>
    </div>
    <div v-else>
      <div class="o-container-gray">
        <form novalidate class="md-layout" @submit.prevent="validateUser">
          <md-card>
            <md-progress-bar md-mode="indeterminate" v-if="isAuthLoading" />
            <md-card-header>
              <div class="md-title">{{ $t('signUpView.title') }}</div>
            </md-card-header>

            <md-card-content>

              <md-field md-clearable :class="getValidationClass('email')">
                <label for="email">{{ $t('common.email') }}</label>
                <md-input v-model.trim="email"
                          type="email"
                          name="email"
                          id="email"
                          autocomplete="email" />
                <span v-if="!$v.email.required" class="md-error">{{ $t('common.auth.pleaseEnterEmail') }}</span>
                <span v-else-if="!$v.email.email" class="md-error">{{ $t('common.auth.incorrectEmail') }}</span>
              </md-field>

              <md-field :class="getValidationClass('password')">
                <label>{{ $t('signUpView.password') }}</label>
                <md-input v-model="password"
                          type="password"
                          name="password"
                          id="password"
                          autocomplete="password" />
                <span class="md-error" v-if="!$v.password.required">{{ $t('common.auth.enterYourPassword') }}</span>
                <span class="md-error" v-else-if="!$v.password.minLength">{{ $t('signUpView.minLengthPassword') }}</span>
              </md-field>

              <md-field :md-toggle-password="false" :class="getValidationClass('passwordConfirmation')">
                <label>{{ $t('signUpView.confirmPassword') }}</label>
                <md-input v-model="passwordConfirmation"
                          type="password"
                          name="passwordConfirmation"
                          id="passwordConfirmation" />
                <span class="md-error" v-if="!$v.passwordConfirmation.required">{{ $t('signUpView.enterPasswordAgain') }}</span>
                <span class="md-error" v-else-if="!$v.passwordConfirmation.sameAsPassword">{{ $t('signUpView.passwordsMustBeIdentical') }}</span>
              </md-field>

              <div v-if="errors" v-html="errors" class="u-default-body u-red"></div>

              <section class="newsletter">
                <md-checkbox v-model="newsletter" class="md-primary"><span class="o-default-body-small">{{ $t('signUpView.newsletterConsent') }}</span></md-checkbox>
              </section>

              <TermsAndConditions />
            </md-card-content>

            <vue-recaptcha ref="invisibleRecaptcha"
                           @verify="onVerify"
                           @expired="onExpired"
                           size="invisible"
                           :sitekey="sitekey">
            </vue-recaptcha>

            <md-card-actions class="u-justify-content-center u-text-center">
              <md-button type="submit" class="md-raised md-primary auth-btn">{{ $t('signUpView.createAccountBtn') }}</md-button>
            </md-card-actions>
          </md-card>

          <!-- <md-snackbar :md-active.sync="userSaved">{{ $t('signUpView.userSaved') }} {{ lastUser }} {{ $t('signUpView.userSaved2') }}</md-snackbar> -->
        </form>
      </div>

      <div class="o-container-gray">
        <md-button @click="Helpers.goToRoute('sign-in', $router)"
                   class="md-raised md-primary sign-in-btn">
          {{ $t('signUpView.goToSignIn') }}
        </md-button>
      </div>
    </div>

    <md-snackbar :md-position="snackbar.position" :md-duration="snackbar.duration" :md-active.sync="snackbar.show" md-persistent>
      <span>{{ $t('signUpView.congratsSuccessfullyRegistered') }}</span>
    </md-snackbar>

    <p class="o-default-body">{{ $t('common.error.furtherIssuesContact') }} {{ $t('contactEmailInreg') }}</p>

    <SecureLock />
  </div> <!-- /.SignUpView -->
</template>

<script>
import { mapState } from 'vuex';
import {
  START_AUTH_LOADER,
  SIGNUP,
  RESET_AUTH_ERROR
} from '@/store/actions.type';
import { validationMixin } from 'vuelidate';
import {
  required,
  email,
  minLength,
  sameAs
} from 'vuelidate/lib/validators';
import VueRecaptcha from 'vue-recaptcha';
import SecureLock from '@/components/util/SecureLock';
import TermsAndConditions from '@/components/TermsAndConditions';


export default {
  name: 'SignUpView',
  mixins: [validationMixin],
  components: {
    VueRecaptcha,
    SecureLock,
    TermsAndConditions
  },
  data() {
    return {
      email: '',
      password: '',
      passwordConfirmation: '',
      newsletter: false,
      signUpRequestSent: false,
      userEmail: '',
      snackbar: {
        show: false,
        position: 'center',
        duration: 4000
      },
      links: {
        privacy: {
          page: '/privacy-policy'
        },
        terms: {
          page: '/terms'
        },
      },
      sitekey: '6LehkZMUAAAAAOua6fmkshH_FUe0rkw0v45UXjIS'
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      isAuthLoading: state => state.auth.isAuthLoading
    })
  },
  mounted() {
    this.resetErrors();
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm() {
      this.$v.$reset()
      this.email = null
      this.password = null
      this.passwordConfirmation = null
    },
    validateUser() {
      this.$v.$touch()

      this.$refs.invisibleRecaptcha.execute();
    },
    signUpUser(response) {
      this.$store.dispatch(START_AUTH_LOADER);

      this.$store
        .dispatch(SIGNUP, {
          email: this.email,
          password: this.password,
          newsletter: this.newsletter,
          captchaToken: response
        })
        .then((data) => {
          this.signUpRequestSent = true;
          this.userEmail = data.user.email;
          this.snackbar.show = true;
        });
    },
    onVerify(response) {
      this.$refs.invisibleRecaptcha.reset();

      if (!this.$v.$invalid) {
        this.signUpUser(response);
      }
    },
    onExpired() {
      this.$refs.invisibleRecaptcha.reset();

      // eslint-disable-next-line
      console.log('Expired');
    },
    newTabOpenRoute(path) {
      let route = this.$router.resolve(path);
      window.open(route.href, '_blank');
    },
    resetErrors() {
      this.$store.dispatch(RESET_AUTH_ERROR);
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6),
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs('password'),
    },
  },
};
</script>

<style lang="scss">
.SignUpView {

  .md-button .md-button-content {
    font-size: 1.6rem;
    line-height: 1;
  }
}
</style>

<style lang="scss" scoped>
.SignUpView {

  .md-card {
    margin-left: auto;
    margin-right: auto;
    padding: 1.6rem;
    width: 66rem;
  }

  .md-checkbox {
    width: 100%;
  }

  .md-field:last-child {
    margin-bottom: 4rem;
  }

  .o-default-h3,
  .o-default-h4 {
    text-align: center;
  }

  .navigate-to-main-page {
    margin-top: 3.2rem;
  }

  .navigate-btn {
    background-color: $palette-primary-color-four !important;
    border-radius: .4rem;
    box-shadow: none;
    font-family: $font-family-heading-regular;
    height: 3.8rem;
    letter-spacing: .1rem;
    margin-bottom: 1.6rem;
    text-transform: none;
  }

  .auth-btn {
    background-color: $palette-primary-color-four !important;
    height: 4rem;
    margin-bottom: .8rem;
    width: 100%;
  }

  .newsletter {

    .o-default-h4 {
      text-align: left;
    }
  }

  .sign-in-btn {
    height: 4rem;
    padding: 0 .8rem;
  }
}
</style>
